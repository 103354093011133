import React, { useState } from 'react'
import Layout from '../components/layout'
import { Container, Row, Col, Modal, ModalBody } from 'reactstrap'
import styled from 'styled-components'
import { graphql, StaticQuery } from 'gatsby'
import { TiGroup } from 'react-icons/ti'
import { GiBrain } from 'react-icons/gi'
import { FaRegLightbulb } from 'react-icons/fa'
import Fade from 'react-reveal/Fade';
import Img from "gatsby-image"
import Icon1 from '../images/diversity/icon1.svg'
import Icon2 from '../images/diversity/icon2.svg'
import Icon3 from '../images/diversity/icon3.svg'
import Icon4 from '../images/diversity/icon4.svg'
import Icon5 from '../images/diversity/icon5.svg'

import Img1 from '../images/diversity/ppt1.png'
import Img2 from '../images/diversity/ppt02.png'
import Img3 from '../images/diversity/pie-chart.png'
import Img4 from '../images/diversity/road_map.png'
import Img5 from '../images/diversity/color_line.png'
import BlogForm from './Company/Blog/BlogForm'
const Diversity = (props) => {
    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);

    return (
        <StaticQuery
            query={graphql`
          query {
            Banner: file(relativePath: { eq: "diversity_banner.jpg" }) {
              childImageSharp {
                fluid(quality: 90, maxWidth: 1220,maxHeight:400,) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            } 
    
          }
        `}
            render={data => {
                const imageData = data.Banner.childImageSharp.fluid
                const {
                    // buttonLabel,
                    className
                } = props;



                return (
                    <Layout path="/diversity/">
                        {/* <section>
                        <div className="banner_section">
                            <Img
                                fluid={imageData}
                                className="startup_background"
                            ></Img>
                        </div>
                        <div className="first_banner">
                            <Container>
                                <Row>
                                    <Col sm="12" md={{ size: 7, offset: 6 }}>
                                        <Fade right>
                                            <div>
                                                <h1>Did the big search engine firm just outbid your offer?</h1>
                                            </div>
                                        </Fade>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </section> */}
                        <section className="banner_min">
                            <div className="banner_section">
                                <Img
                                    fluid={imageData}
                                    className="startup_background"
                                ></Img>
                            </div>
                            <div className="banner_blur3">
                                <div className="first_banner">
                                    <Container>
                                        <Row>
                                            <Col sm="12" md={{ size: 7, offset: 6 }}>
                                                <Fade right>
                                                    <div>
                                                        {/* <h1>Did the big search engine firm just outbid your offer?</h1> */}
                                                        <h1>Diversity as a catalyst to success</h1>
                                                    </div>
                                                </Fade>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </div>
                        </section>
                        <section className="heading_section_bg">
                            <Container>
                                <div className="heading_section">
                                    {/* <p>Trident is an active and committed company to encourage diversity in the workplace. Diversity has evolved beyond visible characteristics like gender, race, or age to also include beliefs, skills and talents, life experiences, thought processes, and working styles.</p> */}
                               <p>Most corporations today have embraced D&I as part of their corporate philosophy. Trident is proud to work with companies who believe that recruiting from diverse groups helps increase innovation and improve retention, and therefore the bottom line.</p>
                                </div>
                            </Container>
                        </section>
                        <section className="startup_section">
                            <div className="diversity_details">
                                <Container>
                                    <Row>
                                        <Col md="12">
                                            <div>
                                                {/* <p>Most corporations today have embraced D&I as part of their corporate philosophy. Trident is proud to work with companies who believe that recruiting from diverse groups helps increase innovation and improve retention, and therefore the bottom line.</p> */}
                                                <p>We understand how crucial this is, in today’s context more than ever. Trident’s team of discerning recruiters identify and source from the massive untapped talent pool of diverse candidates across the nation. We believe that the policy of diversity and inclusion will enhance business performance for our clients and create a healthy work experience for their employees. As per a PwC report, companies with a higher percentage of diversity teams have recorded 19% higher revenue.</p>
                                                <p>That said, at Trident we have devised a comprehensive plan on how we help our client achieve their D&I goals. This year, with all the curveballs that the pandemic threw at us, Trident has helped hire 64% diverse candidates for our clients at positions, which make a difference in the corporate hierarchy.</p>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </section>
                        <section className="color_section">
                            <Container>
                                <div className="text_center">
                                    <h3 style={{marginBottom:'20px'}} >Harness the True Power of Diversity</h3>
                                </div>
                                <Row>
                                    <Col md="12">
                                        <p className="text_center">Diverse organizations ensure they’re meeting their hiring needs with talent that pushes the boundaries of what’s possible.<br/> Here’s how they do it.</p>
                                    </Col>
                                    <Col md="6">
                                        <div className="text_center">
                                            <img src={Img1} alt="img" />
                                        </div>
                                    </Col>
                                    <Col md="6">
                                        <div className="text_center">
                                            <img src={Img2} alt="img" />
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </section>
                        <section className="startup_section">
                            <Container>
                                <Row className="pie_chart">
                                    <Col md="6">
                                        <div className="pie_chart_text">
                                            <h3>We continually audit ourselves on diversity to measure our success</h3>
                                            <p>We hired 64% diverse permanent employees for our clients in the roles that make a difference to the overall workforce</p>
                                        </div>
                                    </Col>
                                    <Col md="6">
                                        <img src={Img3} alt="img" />
                                    </Col>

                                </Row>
                            </Container>
                        </section>
                        <section className="color_section" >
                            <Container>
                                <div className="text_center">
                                    <h3>Take a look at our AESSIAO plan to understand our D&I hiring strategies.</h3>
                                    <br />
                                    <img src={Img5} alt="img" width="50%" />
                                    <br />
                                    <br />
                                </div>
                                <Row>
                                    <Col md="12">
                                        <img className="road_map" src={Img4} alt="img" />
                                    </Col>

                                </Row>
                            </Container>
                        </section>
                        <section className="startup_section" >
                            <Container>
                                <div className="text_center">
                                    <h3>We help our clients leverage the benefits of Diversity in the workplace</h3>
                                    <br />
                                    <br />
                                </div>
                                <Row>
                                    <Col md="12">
                                        <div className="diver_section">
                                            <div className="diver_section_card diver_right">
                                                <h3>Reduced risk</h3>
                                                <p>Our clients have implemented strong diversity programs and policies to address and resolve potential discrimination and harassment issues. It makes them less vulnerable to penalties in the event of discrimination or harassment lawsuits.</p>
                                            </div>
                                            <div className="diver_section_border">
                                                <div className="diver_section_icon diver_section_border2">
                                                    <span className="diver_section_icon_border">
                                                        <TiGroup className="down" size={30} />
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="diver_section_card">
                                                <div className="diver_section_text2">
                                                    <h3>Ability to attract and retain employees</h3>
                                                    <p>Companies that have a strong commitment to diversity often find it easier to recruit and retain talented and diverse employees. Studies show that employees are more likely to recommend an employer that is socially responsible, even in difficult labor markets. Today’s millennials will easily switch jobs if their core values conflict with those of their employer.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="diver_section">
                                            <div className="diver_section_card diver_right">
                                                <h3>Expanded talent base</h3>
                                                <p>We have worked with many companies who practice diversity and inclusion find better talent and retain them.</p>
                                            </div>
                                            <div className="diver_section_border">
                                                <div className="diver_section_icon">
                                                    <span className="diver_section_icon_border">
                                                        <FaRegLightbulb className="down" size={30} />
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="diver_section_card">
                                                <div className="diver_section_text2">
                                                    <h3>Expanded supplier base</h3>
                                                    <p>By establishing relationships with suppliers in increasingly diverse markets, our clients gain in diverse markets.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="diver_section">
                                            <div className="diver_section_card diver_right">
                                                <h3>Greater innovation</h3>
                                                <p>Contributions from people of diverse experiences and perspectives allow companies to improve innovation by generating new ideas and highlighting emerging issues.</p>
                                            </div>
                                            <div className="diver_section_border">
                                                <div className="diver_section_icon">
                                                    <span className="diver_section_icon_border">
                                                        <GiBrain className="down" size={30} />
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="diver_section_card">
                                                <div className="diver_section_text2">
                                                    <h3>Financial gains</h3>
                                                    <p>By working with a diversity supplier like Trident, our clients gain financially from Federal tax breaks.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </section>
                        <section className="startup_bg">
                            <div className="startup_section">
                                <Container>
                                    <div className="text_center">
                                        <h3>How can you improve your company’s workplace diversity?</h3>
                                        <h4>Together we can leverage these statistics to learn how to build and manage diversity in your company</h4>
                                    </div>
                                    <div className="text_center">
                                        <h3 className="text_color">Top 5 workplace diversity statistics</h3><br />
                                    </div>
                                    <Row>

                                        <Col md="4">
                                            <div className="workplace">
                                                <img src={Icon1} width={80} alt="img" />
                                                <p>Workplace diversity leads to innovation.
                                                   Inclusive companies are 1.7 times more likely to be innovation leaders in their market
                                        </p>
                                            </div>
                                        </Col>
                                        <Col md="4">
                                            <div className="workplace">
                                                <img src={Icon2} width={80} alt="img" />
                                                <p>Workplace diversity leads to better decision making. Diverse teams made a business decision, they outperformed individual decision-makers up to 87% of the time when it comes to making business decisions.</p>
                                            </div>
                                        </Col>
                                        <Col md="4">
                                            <div className="workplace">
                                                <img src={Icon3} width={80} alt="img" />
                                                <p>Workplace diversity generates higher job acceptance rate.
                                                   67% of job seekers said a diverse workforce is important when considering job offers.
                                        </p>
                                            </div>
                                        </Col>
                                        <Col sm="12" md={{ size: 4, offset: 2 }}>
                                            <div className="workplace">
                                                <img src={Icon5} width={80} alt="img" />
                                                <p>Companies with diverse workforce outperform its competitors.
                                                   Racially and ethnically diverse companies outperform industry norms by 35%
                                        </p>
                                            </div>
                                        </Col>
                                        <Col md="4">
                                            <div className="workplace">
                                                <img src={Icon4} width={80} alt="img" />
                                                <p>Companies with more diverse workplace make more money.
                                                    Companies that have more diverse management teams have 19% higher revenue.
                                           </p>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </section>
                        <section className="footer_section">
                            <Container>
                                <Row>
                                    <Col md="12">
                                        <h1>Try your next talent recruiting with us </h1>
                                        <div className="footer_button">
                                            <a onClick={toggle} href>Contact Us</a>
                                        </div>
                                        <Modal isOpen={modal} toggle={toggle} className={className} className="form_modal">
                                            <ModalBody >
                                                <BlogForm title="Try your next talent recruiting with us"  close={()=>toggle()}/>
                                            </ModalBody>

                                        </Modal>
                                    </Col>
                                </Row>
                            </Container>
                        </section>
                        {/* <BlogForm refItem = {()=>{}} title = "We'd love to hear more about your staffing challenges. Reach out to our team today." redirectTitle="Five Ways Diverse Organizations Hire Differently"/> */}

                    </Layout >
                )
            }}
        />
    )
}
const StyledBackgroundSection = styled(Diversity)`
        width: 100%;
        background-position: bottom center;
        background-repeat: repeat-y;
        background-size: cover;
        margin: 0;
        background-color: inherit !important;
        
        `

export default StyledBackgroundSection